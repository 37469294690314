import React from "react"
import FadeInUp from "../animate/FadeInUp"
import "./style.css"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

const Clients = ({ title }) => {
  const {
    allMarkdownRemark: { edges: clients },
  } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: {
            fileAbsolutePath: { regex: "/(content/clients)/.*\\\\.md$/" }
          }
          limit: 19
        ) {
          edges {
            node {
              frontmatter {
                title
                image {
                  childImageSharp {
                    fixed(height: 155) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  return (
    <div className="clients container">
      <h2 className="main-heading">
        <FadeInUp>{title}</FadeInUp>
      </h2>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {clients.map(({ node: { frontmatter: { image, title } } }, index) => (
          <FadeInUp key={index}>
            <Img fixed={image.childImageSharp.fixed} alt={title} key={index} />
          </FadeInUp>
        ))}
      </div>
    </div>
  )
}
export default Clients
