import React from "react"
import FadeInUp from "../animate/FadeInUp"
import "./style.css"
import { Icon } from "react-icons-kit"
import { envelope } from "react-icons-kit/fa/envelope"
import { cogs } from "react-icons-kit/fa/cogs"
import { compass } from "react-icons-kit/fa/compass"
import { Box } from "theme-ui"

const icons = {
  envelope,
  cogs,
  compass,
}

const About = ({ stepsSection }) => {
  return (
    <div className="b-about">
      <div className="container">
        <div className="row">
          <FadeInUp>
            <div className="col-xs-12 col-sm-12 text-center">
              <h2 className="ml1">{stepsSection.title}</h2>
            </div>
          </FadeInUp>

          <FadeInUp>
            <div className="b-info-columns-holder b-steps-list col-xs-12 col-sm-12">
              <div className="row equal">
                {stepsSection.steps.map(
                  ({ title, icon, description }, index) => (
                    <div
                      key={index}
                      className="b-info-column col-xs-4 col-sm-4"
                    >
                      <Box className="info-column-icon">
                        <Icon size={60} icon={icons[icon]} />
                        <span className="step-number">{index + 1}</span>
                      </Box>
                      <p className="info-column-title">{title}</p>
                      <div className="text-muted">
                        <p>{description}</p>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </FadeInUp>
        </div>
      </div>
    </div>
  )
}

export default About
