import React, { useRef } from "react"
import PropTypes from "prop-types"
import { Box } from "theme-ui"
import { Global, css } from "@emotion/core"
import useOnClickOutside from "../../hooks/useOnClickOutside"

function Modal({ isOpen, background, onClickOutside, children }) {
  const inner = useRef()

  useOnClickOutside(inner, onClickOutside)

  if (!isOpen) return false
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 9999,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Global
        styles={css`
          body {
            overflow: hidden;
          }
        `}
      />
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 9999,
          background: background || "rgba(0,0,0,0.5)",
        }}
      />
      <Box ref={inner} sx={{ position: "relative", top: 0, zIndex: 99999 }}>
        {children}
      </Box>
    </Box>
  )
}

Modal.propTypes = {}

export default Modal
