import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import HomeWelcomeArea from "../components/WelcomeArea"
import About from "../components/About"
import Features from "../components/Features"
import Services from "../components/Services"
import Clients from "../components/Clients"
import { graphql } from "gatsby"
import Arrow from "../components/Arrow"

const IndexPage = ({
  data: {
    services,
    markdownRemark: {
      frontmatter: {
        title,
        lang,
        clientSection,
        stepsSection,
        description,
        forSuccessSection,
      },
    },
  },
}) => (
  <Layout title={title} description={description} lang={lang}>
    <HomeWelcomeArea title={title} />
    <div className="b-homepage-content" style={{ overflow: "hidden" }}>
      <div className="b-layer-main">
        <Arrow />
        <About stepsSection={stepsSection} />
        <Features {...services} />
        <Services {...forSuccessSection} />
        <Clients {...clientSection} />
      </div>
    </div>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query($slug: String!, $lang: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        description
        lang

        stepsSection {
          title
          steps {
            title
            icon
            description
          }
        }

        forSuccessSection {
          moreButton
          list {
            icon
            title
            description
          }
        }

        clientSection {
          title
        }
      }
    }
    services: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/(content/services)/.*\\\\.md$/" }
        frontmatter: { lang: { eq: $lang } }
      }
    ) {
      edges {
        node {
          frontmatter {
            icon
            title
            description
          }
        }
      }
    }
  }
`
