import React from "react"
import "./style.css"
import FadeInUp from "../animate/FadeInUp"
import { FeatureColumn, FeatureColumnWrapper } from "./feature.style"
import useLang from "../../hooks/useLang"
import { Link } from "gatsby"

import { Icon } from "react-icons-kit"
import { paintBrush } from "react-icons-kit/fa/paintBrush"
import { film } from "react-icons-kit/fa/film"
import { code } from "react-icons-kit/fa/code"
import { videoCamera } from "react-icons-kit/fa/videoCamera"
import { shoppingCart } from "react-icons-kit/fa/shoppingCart"
import { userSecret } from "react-icons-kit/fa/userSecret"
import { lightbulbO } from "react-icons-kit/fa/lightbulbO"
import { gamepad } from "react-icons-kit/fa/gamepad"
import { users } from "react-icons-kit/fa/users"
import { cogs } from "react-icons-kit/fa/cogs"

const icons = {
  cogs: cogs,
  film: film,
  code: code,
  videoCamera: videoCamera,
  shoppingCart: shoppingCart,
  userSecret: userSecret,
  lightbulbO: lightbulbO,
  gamepad: gamepad,
  paintBrush: paintBrush,
  users,
}

const Features = ({ edges }) => {
  const isAr = useLang() === "ar"
  const serviceTitle = isAr ? "خدماتنا" : "Our Services"

  return (
    <div className="b-features-columns-holder">
      <h2 className="b-upper-title text-center">{serviceTitle}</h2>
      <div style={{ margin: "80px auto 40px" }} className="container">
        <FeatureColumnWrapper>
          {edges.map(
            ({
              node: {
                frontmatter: { description, icon, title },
              },
            }) => (
              <FeatureColumn key={title}>
                <FadeInUp>
                  <div>
                    <div className="features-column-icon">
                      <Icon size={60} icon={icons[icon]} />
                    </div>
                    <p className="features-column-title">{title}</p>
                  </div>
                  <div className="features-column-text">{description} </div>
                  {/* <Link
                    to={`${isAr ? "/ar" : ""}/order/`}
                    className="btn btn-primary"
                  >
                    {isAr ? "اطلب الان" : "Order Now"}
                  </Link> */}
                </FadeInUp>
              </FeatureColumn>
            )
          )}
        </FeatureColumnWrapper>
      </div>
    </div>
  )
}

export default Features
