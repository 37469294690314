import styled from "styled-components"

export const FeatureColumnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`
export const FeatureColumn = styled.div`
  display: flex;
  flex: 1 350px;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px;
  padding: 40px 40px 50px;
  background: #fff;
  text-align: center;
  -webkit-box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.1);
  min-height: 450px;
  .features-column-icon {
    font-size: 48px;
    margin-bottom: 25px;
    position: relative;
  }
  .features-column-title {
    font-size: 24px;
    margin: 0 0 22px;
    color: #23272c;
    position: relative;
  }
  .features-column-text {
    font-size: 14px;
    margin-bottom: 42px;
    position: relative;
    text-align: justify;
  }
  .btn {
    padding: 16px 50px;
    position: relative;
  }

  .b-features-column:hover .btn,
  .b-features-column-mod:hover .btn {
    color: #fff;
    background: rgba(255, 255, 255, 0.3);
    border-color: transparent;
  }

  :hover {
    box-shadow: 0px 1px 14px 6px rgba(0, 0, 0, 0.1);
  }

  :hover .features-column-title {
    background: linear-gradient(to bottom, #7e9822, #7e9822, #3b4c00);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  :hover .btn {
    color: #fff;
    background: linear-gradient(to bottom, #7e9822 0%, #3b4c00 100%);
  }
  :hover svg path {
    fill: #7e9822;
    background: linear-gradient(to bottom, #7e9822 0%, #3b4c00 100%);
  }
`
