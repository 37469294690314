import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import { Icon } from "react-icons-kit"
import { compass } from "react-icons-kit/fa/compass"
import { film } from "react-icons-kit/fa/film"
import { code } from "react-icons-kit/fa/code"
import { videoCamera } from "react-icons-kit/fa/videoCamera"
import { shoppingCart } from "react-icons-kit/fa/shoppingCart"
import { userSecret } from "react-icons-kit/fa/userSecret"
import { lightbulbO } from "react-icons-kit/fa/lightbulbO"
import { gamepad } from "react-icons-kit/fa/gamepad"
import { cogs } from "react-icons-kit/fa/cogs"
import { binoculars } from "react-icons-kit/fa/binoculars"

import FadeInUp from "../animate/FadeInUp"
import useLang from "../../hooks/useLang"

import "./style.css"

const icons = {
  binoculars: binoculars,
  cogs: cogs,
  film: film,
  code: code,
  videoCamera: videoCamera,
  shoppingCart: shoppingCart,
  userSecret: userSecret,
  lightbulbO: lightbulbO,
  gamepad: gamepad,
  compass: compass,
}

const Services = ({ list, moreButton }) => {
  const [active, setActive] = useState(0)
  const isAr = useLang() === "ar"
  const title = isAr ? "لنجاح الأعمال!" : "For a business success!"
  const {
    placeholderImage: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "page-layer-bg2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <div
      style={{
        backgroundImage: `url(${fluid.src})`,
        backgroundAttachment: "fixed",
      }}
      className="b-about-tabs page-layer-bg2"
    >
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 text-center">
            <FadeInUp>
              <h3 className="tabs-title">{title}</h3>
            </FadeInUp>
            <FadeInUp>
              <ul className="tabs-controls">
                {list.map(({ icon, title }, index) => (
                  <li key={index} className={active === index ? "active" : ""}>
                    <div
                      role="button"
                      aria-label={title}
                      className="pointer"
                      style={active === index ? { color: "#33c187" } : null}
                      onClick={() => setActive(index)}
                    >
                      <span className="tabs-icon">
                        <Icon size={35} icon={icons[icon]} />
                      </span>
                      <span className="tabs-name">{title}</span>
                    </div>
                    <span className="tabs-circle line-after line-before">
                      <span
                        role="button"
                        aria-label={title + "Dot"}
                        className="inner-circle pointer"
                        onClick={() => setActive(index)}
                      ></span>
                    </span>
                  </li>
                ))}
              </ul>
            </FadeInUp>
            <div className="row">
              <div className="col-lg-12">
                <FadeInUp>
                  <div className="text-center">
                    <p className="mb-4">{list[active].description}</p>
                    <Link
                      aria-label={title}
                      to={`${isAr ? "/ar" : ""}/services/`}
                      className="btn btn-additional mt-4"
                    >
                      {moreButton}
                    </Link>
                  </div>
                </FadeInUp>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Services
