import React, { useState } from "react"
import { Box, Button } from "theme-ui"
import useInterval from "../../hooks/useInterval"
import Icon from "react-icons-kit"
import { playCircle } from "react-icons-kit/fa/playCircle"
import useLang from "../../hooks/useLang"
import AniLink from "../AntiLink"
import Modal from "../Modal"

const thingsen = [
  "Design Foundation",
  "Development",
  "Marketing Foundation",
  "Security",
]
const thingsar = ["التصميم", "التطوير", "التسويق", "الشبكات"]

const WelcomeArea = () => {
  const isAr = useLang() === "ar"
  const [couner, setCouner] = useState(0)
  const [openmodal, setOpenmodal] = useState(false)
  const thingPoitionTop = 60 * couner
  const estimateServiceText = isAr ? "طلب خدمة" : "Estimate Services"
  const slogo = isAr ? "بنشمارك مؤسسة متكاملة من " : "Benchmark We Create Your"
  const previewServices = isAr ? "إستعراض الخدمات" : "Preview our service"
  const things = isAr ? thingsar : thingsen

  const handleToggleModal = () => setOpenmodal(!openmodal)

  const handleUpdateCounter = () =>
    couner >= things.length - 1 ? setCouner(0) : setCouner(couner + 1)

  useInterval(handleUpdateCounter, 3000)

  return (
    <Box
      sx={{
        minHeight: "calc(100vh - 200px)",
        display: "flex",
        alignItems: "center",
        backgroundColor: "background",
        mt: 5,
        py: 5,
      }}
    >
      <div className="container">
        <Box
          as="h1"
          sx={{
            py: 5,
            fontSize: [5, 5, 6],
            lineHeight: "60px",
            position: "relative",
            textAlign: "center",
          }}
        >
          <Box as="span">{slogo} </Box>
          <Box
            sx={{
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              width: "100%",
              height: 60,
              overflow: "hidden",
            }}
          >
            {isAr && <Box>المبدعين في</Box>}
            <Box
              as="span"
              sx={{
                transition: "all linear 400ms",
                mt: -thingPoitionTop,
                mx: 2,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                minWidth: 100,
                background:
                  "linear-gradient(to bottom, #7e9822, #7e9822, #3b4c00)",
                "-webkit-background-clip": "text",
                "-webkit-text-fill-color": "transparent",
              }}
            >
              {things.map((thing, index) => (
                <Box
                  sx={{
                    visibility: index === couner ? "visible" : "hidden",
                    transition: "all linear 400ms",
                  }}
                  as="strong"
                >
                  {thing}
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            mt: 5,
          }}
        >
          <Button
            onClick={handleToggleModal}
            onKeyPress={handleToggleModal}
            sx={{ marginInlineEnd: 20 }}
            variant="outline"
          >
            <Icon size={45} icon={playCircle} />
            <span>{previewServices}</span>
          </Button>
          <AniLink to={`${isAr ? "/ar" : ""}/contact`}>
            <span style={{ padding: "20px 5px", display: "inline-block" }}>
              {estimateServiceText}
            </span>
          </AniLink>
        </Box>
        <Modal
          onClickOutside={handleToggleModal}
          background="#000"
          isOpen={openmodal}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <video autoPlay controls muted>
              <source
                src={require("../../assets/videos/services.mp4")}
                type="video/mp4"
              />
              <source
                src={require("../../assets/videos/services.webm")}
                type="video/webm"
              />
            </video>
          </Box>
        </Modal>
      </div>
    </Box>
  )
}
export default WelcomeArea
